import './LegalPages.css'
function PrivacyPolicy() {
    return (
        <div className={'doc'}>
            <div className={'doc-wrapper'}>
                <p className={'doc-header'}>
                    Privacy Policy
                </p>
                <p className={'doc-text last-amended'}>
                    Last Amended 07-26-2024
                </p>
                <p className={'doc-subheader'} id={'doc-subheader--first'}>
                    Preamble
                </p>
                <p className={'doc-text border-primary'}>
                    This Privacy Policy (hereinafter – the Policy) and other documents that are referred to herein
                    explain how and why we, the Service Provider, collect
                    and process information and how you,
                    the User, can update, manage, export and delete your information. We reserve the right to
                    amend this Policy at our discretion at any time. If you no longer want your information to be
                    treated in accordance with this Policy, you may at any time revoke your consent and delete
                    account. Make sure you have independent control over all your data and funds before doing so.
                </p>
                <p className={'doc-text'}>
                    The controller of data processing is Streamiverse LLS, Euro House, Richmond Hil Road, Kingstown, St. Vincent and the Grenadines.
                </p>
                <br/>
                <p className={'doc-text'}>
                    To contact our data protection officer, please address correspondence to the “Data Protection
                    Officer” and send an email to: email@domain.
                </p>
                <p className={'doc-subheader'}>
                    What Information We May Collect
                </p>
                <ul className={'doc-text'}>
                    <li>Email;</li>
                    <li>Password;</li>
                    <li>Backup Mnemonic;</li>
                    <li>History of Transactions;</li>
                    <li>Cookies;</li>
                    <li>Settings and choices;</li>
                    <li>
                        Other information that you have chosen to provide us or that is necessary for us to
                        secure the continuity of our services.
                    </li>
                </ul>
                <p className={'doc-subheader'}>
                    Why We Collect Information
                </p>
                <p className={'doc-text'}>
                    We use data to build our services: provide services, maintain and improve, develop new
                    services, measure performance and analyze the way our services are used, communicate to you
                    and protect you, other users and the public.
                </p>
                <p className={'doc-subheader'}>
                    How We Process Information
                </p>
                <p className={'doc-text'}>
                    The processing of the data may be performed manually or by automated means with the help
                    of suitable tools and may include all types of processing, such as collection, structuring, storage,
                    retrieval, use, disclosure by transmission and erasure.
                </p>
                <p className={'doc-subheader'}>
                    How We Protect Information
                </p>
                <p className={'doc-text'}>
                    We build and regularly review our practices for collection, storage and processing of
                    information to prevent unauthorized access to our systems.
                    We restrict access to information to our employees, contractors, and agents on a
                    “need-to-know” basis in order to process it. Anyone with this access is subject to strict
                    contractual confidentiality obligations.
                </p>
                <p className={'doc-subheader'}>
                    How We Can Share Information
                </p>
                <p className={'doc-text'}>
                    We do not share your personal information without your consent with third-party companies,
                    organizations, or individuals except in the following cases.
                </p>
                <p className={'doc-subheader-2'}>Legal Reasons To Share Your Information</p>
                <p className={'doc-text'}>
                    We may disclose your information to obey with an order or demand of competent authority,
                    law or regulation, when there is a necessity to enforce Terms of Use or we believe there is a
                    security reason to detect, investigate, prevent violation or protect you, other users, public or
                    our services.
                </p>
                <p className={'doc-subheader-2'}>Functional Reasons To Share Your Information</p>
                <p className={'doc-text'}>
                    Some of our services are provided via third party contractors. We may provide your information
                    to our contractors and other trusted parties in order to secure uninterruptible services for you
                    or other users, based on our instructions and in compliance with our Privacy Policy and any
                    other appropriate confidentiality and security measures.
                </p>
                <p className={'doc-subheader'}>
                    How You Can Control Your Information
                </p>
                <p className={'doc-subheader-2'}>Export of Your Information</p>
                <p className={'doc-text'}>
                    You at any time may request export of all or part of your information that we keep.
                </p>
                <p className={'doc-subheader-2'}>Control Over Cookies</p>
                <p className={'doc-text'}>
                    We use Google Analytics on our website. You can control and/or delete cookies if you wish. If
                    you want to know more about Google Analytics and its “do not track” policy, please
                    visit <a className={'a-default'} href={'https://www.google.com/analytics/terms/default.html'} target='_blank' rel="noreferrer">https://www.google.com/analytics/<wbr/>terms/default.html</a>
                </p>
                <p className={'doc-subheader-2'}>Consent And Its Withdrawal</p>
                <p className={'doc-text'}>
                    Your consent can be withdrawn at any time without having to give any reasons. This shall not
                    affect the lawfulness of processing based on consent before its withdrawal. If consent is not
                    withdrawn, it is valid for an unlimited period of time. Your consent is given voluntarily. Please
                    note that withdrawal of your consent may lead to your account suspension or deletion.
                </p>
                <p className={'doc-subheader-2'}>Right To Be Forgotten</p>
                <p className={'doc-text'}>
                    If you wish so you may at any time and at no cause request to close your account and delete all
                    your information from our services. Please note that such a request will also terminate your
                    account with us.
                </p>
                <p className={'doc-subheader-2'}>Requests For Information And Actions With It</p>
                <p className={'doc-text'}>
                    Please send all information requests, complaints and questions in English to email
                    support@streamiverse.io. It may take up to 30 days for us to process your request.
                </p>
            </div>
        </div>
    )
}

export default PrivacyPolicy;
